import React from "react";
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby"

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, AppBar, Container, useScrollTrigger, Toolbar } from "@material-ui/core";
import Logo from "../Logo";
import UserButton from "../UserButton";
import Footer from "./Footer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootLayout: {
        minHeight: '100vh',
      },
      fullSpace: {
        flex: 1,
      },
      space: {
        width: theme.spacing(1),
      },
      appbarPadding: {
        ...theme.mixins.toolbar,
      },
      content: {
        minHeight: 'calc(100vh - 64px - 161px)',
        [theme.breakpoints.down('sm')]: {
          minHeight: 'calc(100vh - 56px - 161px)',
        }
      }
    }
  ),
);

interface Props {
  title: string;
  maxWidth?: boolean;
  appBarElevation?: boolean;
  className?: string;
}

const Layout: React.FC<Props> = ({ children, title, maxWidth, appBarElevation, className }) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64,
  });

  return (
    <div className={classes.rootLayout}>
      <Helmet>
        <html lang="en" />
        <title>{title} - repix.app</title>
        <meta property="og:title" content={`${title} - repix.app`} />
        <meta name="description" content="Repix.app is an Android & iOS screenshot designer that you can use to create beautiful App Store & Google Play images for your app & export them in resolutions Apple & Google require." />
        <meta property="og:image" content={`${process.env.SITE_URL}/og_repix.jpg`} />
      </Helmet>
      <AppBar position="fixed" elevation={trigger || appBarElevation ? 1 : 0} style={{ opacity: trigger ? 0.95 : 1 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Logo linkTo="/" />
            <div className={classes.fullSpace} />
            <UserButton />
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.appbarPadding} />
      <Container className={clsx(classes.content, className)} maxWidth={maxWidth ? 'lg' : false} disableGutters={!Boolean(maxWidth)}>
        {children}
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;