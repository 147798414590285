import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import Layout from "../components/Layout/Layout";
import Link from "../components/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootPricing: {
        paddingTop: theme.spacing(10),
        textAlign: 'center',
      },
      link: {
        textDecoration: 'underline',
      }
    }
  ),
);

const Pricing: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout title="Pricing" appBarElevation maxWidth className={classes.rootPricing}>
      <Typography variant="h5" paragraph>
        Repix.app is FREE for unlimited use. Enjoy :)
      </Typography>
      <Typography paragraph>As you use Repix, we would love to hear your <Link to="/contact-us" color="inherit" className={classes.link}>feedback</Link>.</Typography>
      <Typography variant="caption">We will greatly appreciate if you support us by sharing the link.</Typography>
    </Layout>
  );
};

export default Pricing;