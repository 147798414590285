import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, IconButton } from "@material-ui/core";
import Logo from "../Logo";
import { Link } from "gatsby";

import InstagramIcon from '../../icons/instagram.svg';
import FacebookIcon from '../../icons/facebook.svg';
import YoutubeIcon from '../../icons/youtube.svg';
import TwitterIcon from '../../icons/twitter.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootFooter: {
        backgroundColor: '#333',
      },
      footerContent: {
        padding: theme.spacing(4, 2),
        color: '#fff',
      },
      link: {
        color: '#fff',
        '&:hover': {
          color: theme.palette.secondary.main,
        }
      },
      linksBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      topBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      bottomBox: {
        marginTop: theme.spacing(4),
        display: 'flex',
        // flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      icon: {
        fill: '#ccc',
        width: 45,
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      socialIcons: {
        display: 'flex',
      }
    }
  ),
);

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.rootFooter}>
      <Container maxWidth="lg" className={classes.footerContent}>
        <div className={classes.topBox}>
          <Logo light linkTo="/" />
          <div className={classes.socialIcons}>
            <IconButton color="inherit" component="a" href="https://www.facebook.com/repixapp" target="_blank" className={classes.icon} rel="noreferrer" title="Repix.app Facebook Page">
              <FacebookIcon className={classes.icon} />
            </IconButton>
            <IconButton color="inherit" component="a" href="https://twitter.com/repix_app" target="_blank" className={classes.icon} rel="noreferrer" title="Repix.app Twitter">
              <TwitterIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.linksBox}>
            <Link to="/pricing" className={classes.link}>Pricing</Link>
            <div className={classes.space} />
            <Link to="/contact-us" className={classes.link}>Contact Us</Link>
          </div>
        </div>
        <div className={classes.bottomBox}>
          <Typography variant="body2">©2020 Repix.app</Typography>
          <div className={classes.space} />
          <div className={classes.space} />
          <Link to="/privacy" className={classes.link}>Privacy Policy</Link>
          <div className={classes.space} />
          <Link to="/terms" className={classes.link}>Terms and Conditions</Link>
        </div>
      </Container>
    </div>
  );
};

export default Footer;