import React from "react";
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import clsx from 'clsx';
import { Theme, Button, Popover, MenuItem, Divider, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";

import IconDropdown from '@material-ui/icons/ArrowDropDownRounded';
import IconAccount from '@material-ui/icons/AccountCircle';

import { actions } from '../services/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      buttonUser: {
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        }
      },
      iconButtonUser: {
        paddingLeft: theme.spacing(2),
        display: 'none',
        [theme.breakpoints.down('xs')]: {
          display: 'inline-block',
        }
      },
      buttonSignIn: {
        padding: theme.spacing(1, 2),
      },
    }
  ),
);

interface Props {
  className?: string;
}

const UserButton: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  }

  const signOut = () => {
    close();
    dispatch(actions.signOut());
  }


  if (!state.user) {
    return <Button className={clsx(className, classes.buttonSignIn)} component={Link} to="/user/sign-in">Sign In</Button>;
  }



  return (
    <React.Fragment>
      <Button className={clsx(className, classes.buttonUser)} onClick={open}>
        {state.user.firstName}
        <IconDropdown />
      </Button>

      <IconButton className={clsx(className, classes.iconButtonUser)} onClick={open}>
        <IconAccount />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          state.user.roles.includes('admin') ? (
            <MenuItem component={Link} to="/admin/">Admin</MenuItem>
          ) : null
        }
        <MenuItem component={Link} to="/user/account">My Account</MenuItem>
        <Divider />
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Popover>
    </React.Fragment>
  );
};

export default UserButton;